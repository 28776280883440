<template>
  <div class="home">
    <loading :loading="isLoading"></loading>
    <SectionHeader
    :name="translatedCareer.name"
    :description="translatedCareer.description"
    :progress="0"
    :loading="isLoading"
    hasBackButton
    />

    <!-- SKELETONS LOADING -->
    <b-row class="main-container py-5 px-md-5" v-if="isLoading">
      <b-col class="mb-3" sm="12" md="6" lg="4" xl="3" v-for="n in Array(12)" v-bind:key="n">
        <CategoryCardSkeleton />
      </b-col>
    </b-row>
    <!-- END SKELETONS LOADING -->

    <div class="main-container px-md-5 py-5">
      <b-row>
        <b-col class="mb-3" sm="12" md="6" lg="4" xl="3"
        v-for="content in translatedCareer.work_roles_nice"
        v-bind:key="content.id">
          <CategoryCard
            :img="getUrlImg(content.image)"
            :title="content.name"
            :text="content.description"
            :progress="calculateProgress(content.score, content.total_points)"
            :to="{ name: 'workRoleDetail', params: { id: content.id, name: content.title } }"
          />
        </b-col>
      </b-row>

      <NoResults v-show="!isLoading" v-if="!hasWorkroles" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CategoryCard from '@/components/utils/cards/CategoryCard'
import SectionHeader from '@/components/SectionHeader'
import CategoryCardSkeleton from '@/components/skeletons/CategoryCardSkeleton'

import ProgressMixin from '@/services/mixins/progress.mixins'
import mapper from '@/services/mixins/mapper.mixins'
import i18nmixin from '@/services/mixins/i18n.mixins'
import NoResults from '@/components/utils/NoResults'

export default {
  name: 'CareerDetail',
  mixins: [i18nmixin, ProgressMixin, mapper],
  components: { CategoryCard, CategoryCardSkeleton, NoResults, SectionHeader },
  data () {
    return { }
  },
  methods: {
    ...mapActions({
      getCareerDetail: 'careers/getCareerDetail'
    })
  },
  computed: {
    ...mapGetters({
      careerDetail: 'careers/getCareerDetail',
      isLoading: 'careers/isLoading'
    }),

    hasWorkroles () { return this.careerDetail?.work_roles_nice?.length > 0 },

    translatedCareer () { return this.translateObject(this.careerDetail) }
  },
  mounted () {
    this.getCareerDetail(this.$route.params.id)
  }
}
</script>
